import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useRecoilValue } from 'recoil'

import { currentUserSelector } from '../state/selectors'

const HamburgerMenuContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
`

const NavList = styled.ul`
  width: 200px;
`

const NavListItem = styled.li`
  a {
    color: #F6F8F7;
    padding: 18px;
    text-align: left;
    border-bottom: 1px solid #F6F8F7;
    cursor: pointer;
    display: block;

    svg {
      width: 14px;
      display: inline;
      vertical-align: top;
      margin-right: 8px;
    }
  }

  &:last-child {
    a {
      border-bottom: none;
    }
  }

  &:hover {
    background: #F6F8F7;

    a {
      color: #282828;
    }
  }
`

export default function Nav() {
  const currentUser = useRecoilValue(currentUserSelector)

  // https://github.com/vercel/next.js/discussions/17443
  // probably another good reason to shift away from nextjs
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    setMounted(true)
  }, [])

  return mounted && <>
    <HamburgerMenuContainer>
      <NavList>
        {currentUser &&
          <NavListItem>
            <a href={`/${currentUser.id}`}>
              <MyProfileIcon /> My Profile
            </a>
          </NavListItem>
        }
        {!currentUser &&
          <NavListItem>
            <a href='/login'>
              <MyProfileIcon /> Login
            </a>
          </NavListItem>
        }
        <NavListItem>
          <a href='/search'>
            <EmployerIcon /> I'm an employer
          </a>
        </NavListItem>
        {!currentUser &&
          <NavListItem>
            <a href='/'>
              <EmployerIcon /> I'm a developer
            </a>
          </NavListItem>
        }
        <NavListItem>
          <a href='/privacy'>
            <PrivacyIcon /> Privacy
          </a>
        </NavListItem>
        <NavListItem>
          <a href='/pricing'>
            <PricingIcon /> Pricing
          </a>
        </NavListItem>
        <NavListItem>
          <a href='/blog'>
            <BlogIcon /> Blog
          </a>
        </NavListItem>
        <NavListItem>
          <a href='https://twitter.com/polyfilldotwork/' target='_blank' rel='noreferrer'>
            <TwitterIcon /> Twitter
          </a>
        </NavListItem>
      </NavList>
    </HamburgerMenuContainer>
  </>
}
  
  const MyProfileIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
      </svg>
    )
  }
  
  const EmployerIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
      </svg>
    )
  }
  
  const PrivacyIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
      </svg>
    )
  }
  
  const PricingIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
      </svg>
    )
  }
  
  const BlogIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
      </svg>
    )
  }
  
  const TwitterIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 9.75a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 01.778-.332 48.294 48.294 0 005.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
      </svg>
    )
  }