import { selector } from 'recoil'
import jwt_decode from 'jwt-decode'
import {
  answersState, activeQuestionsState, answeredLastQuestionState,
  questionsOrderState, jwtState
} from './atoms'
import { scrollToSlug } from '../lib/scroll'

export const answersSelector = selector({
  key: 'answersSelector',
  get: ({ get }) => get(answersState),
  set: ({ get, set }, answer) => {
    const answers = get(answersState)
    const slug = Object.keys(answer)[0]

    set(answersState, { ...answers, ...answer })
    set(activeQuestionsSelector, slug)
    set(scrollDownSelector, slug)
  }
})


export const currentUserSelector = selector({
  key: 'currentUserSelector',
  get: ({ get }) => {
    const jwt = get(jwtState)
    return jwt ? jwt_decode(jwt) : jwt
  },
  set: ({ set }, token) => set(jwtState, token)
})

export const activeQuestionsSelector = selector({
  key: 'activeQuestionsSelector',
  get: ({ get }) => get(activeQuestionsState),
  set: ({ get, set }, slug) => {
    const questionsOrder = get(questionsOrderState)
    const activeQuestions = get(activeQuestionsState)
    const nextIndex = questionsOrder.indexOf(slug) + 1

    if (nextIndex >= questionsOrder.length) {
      set(answeredLastQuestionState, true)
    } else {
      set(activeQuestionsState, [...new Set([...activeQuestions, questionsOrder[nextIndex]])])
    }
  }
})

// i dont think this is probably the best way to handle it
// especially because were not setting any data. But we
// need access to the state
export const scrollDownSelector = selector({
  key: 'scrollDownSelector',
  get: () => { },
  set: ({ get }, slug) => {
    const questionsOrder = get(questionsOrderState)
    const activeQuestions = get(activeQuestionsState)
    const nextIndex = questionsOrder.indexOf(slug) + 1

    // Guest viewers of user profiles transition from a profile
    // call-to-action to the questionnaire. This fades the call-to-action
    // background color into the questionnaire's default background color.
    if (nextIndex == 1) {
      const profileActions = document.getElementById('profile-actions')
      if (profileActions) {
        profileActions.classList.remove('color')
      }
    }

    if (nextIndex >= activeQuestions.length && nextIndex < questionsOrder.length) {
      scrollToSlug(slug, questionsOrder[nextIndex])
    }
  }
})

export const scrollUpSelector = selector({
  key: 'scrollUpSelector',
  get: () => { },
  set: ({ get }, slug) => {
    const questionsOrder = get(questionsOrderState)
    const previousIdx = questionsOrder.indexOf(slug) - 1
    const idxBeforePrevious = previousIdx - 1

    scrollToSlug(questionsOrder[idxBeforePrevious], questionsOrder[previousIdx])
  }
})
