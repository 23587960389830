import Head from 'next/head'

export default function Meta({ meta }) {
  const title = (meta && meta.title) || 'Find a developer job you actually want | Polyfill'
  const url = (meta && meta.url) || 'https://polyfill.work'
  const image = (meta && meta.image) || 'https://polyfill.work/preview.png'
  const relativeImage = (meta && meta.relativeImageURL) || '//polyfill.work/preview.png'
  const description = (meta && meta.description) || "Polyfill matches developers with jobs they'll love based on salary, schedule, cultural values, and more."
  const metaDescription = (meta && meta.title) || 'Find a developer job you actually want'
  const author = (meta && meta.author) || 'Polyfill'

  return <>
    <Head>
      <title>{title}</title>
      <meta name='description' content={metaDescription} />
      <link rel='icon' href='/favicon.ico' />
      <link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.png' />
      <link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png' />
      <link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.png' />
      <link rel='manifest' href='/site.webmanifest' />
      <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#5bbad5' />
      <meta name='msapplication-TileColor' content='#da532c' />
      <meta name='theme-color' content='#ffffff' />
      <meta property='og:type' content='website' />
      <meta property='og:url' content={url} />
      <meta property='og:title' content={title} />
      <meta name="image" property='og:image' content={relativeImage} />
      <meta property='og:description' content={description} />
      <meta name="author" property='og:author' content={author} />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:site' content='@polyfilldotwork' />
      <meta name='twitter:domain' value='polyfill.work' />
      <meta name='twitter:title' value={title} />
      <meta name='twitter:description' value={description} />
      <meta name='twitter:image' content={image} />
      <meta name='twitter:url' value={url} />
    </Head>
  </>
}