import styled from 'styled-components'
import { useRecoilValue } from 'recoil'
import { navOpenState } from '../state/atoms'

const MovedByNavContainer = styled.div`
  transform: translate(${props => props.navOpen ? '-200px' : '0'});
  transition: transform .2s ease-in-out;
  position: relative;
  z-index: 2;
  background-color: #F6F8F7;
  color: #282828;
  min-height: 100vh;
`
export default function MovedByNav({ children }) {
  const navOpen = useRecoilValue(navOpenState)
  return <MovedByNavContainer navOpen={navOpen}>{children}</MovedByNavContainer>
}