import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'

export const answersState = atom({
  key: 'answers',
  default: {},
})

export const previousResultsState = atom({
  key: 'previousResults',
  default: null,
})

export const resultsState = atom({
  key: 'results',
  default: null,
})

export const activeQuestionsState = atom({
  key: 'activeQuestion',
  default: [],
})

export const answeredLastQuestionState = atom({
  key: 'answeredLastQuestion',
  default: false,
})

export const questionsOrderState = atom({
  key: 'questionsOrder',
  default: [],
})

export const JWTlocalStorageKey = 'polyfill-persist'

const { persistAtom } = recoilPersist({
  key: JWTlocalStorageKey
})

export const jwtState = atom({
  key: 'jwt',
  default: null,
  effects_UNSTABLE: [persistAtom],
})

export const flashLocalStorageKey = 'polyfill-flash'

const { persistAtom: flashAtom } = recoilPersist({
  key: flashLocalStorageKey
})

export const flashState = atom({
  key: 'flash',
  default: null,
  effects_UNSTABLE: [flashAtom],
})

export const shoppingCartState = atom({
  key: 'shoppingCart',
  default: [],
})

export const pendingMatchesState = atom({
  key: 'pendingMatches',
  default: [],
})

export const trashedMatchesState = atom({
  key: 'trashedMatches',
  default: [],
})

export const navOpenState = atom({
  key: 'navOpen',
  default: false,
})