import { breakpoints, verticalOffsets } from '../styles/shared.css'

export const scrollToSlug = (slug, targetSlug) => {
  setTimeout(() => {
    const targetLi = document.getElementById(targetSlug)
    const top = calculateScrollTop(slug, targetLi)

    window.scrollTo({
      top: top,
      behavior: 'smooth'
    })

    const checkIfScrollIsStatic = setInterval(() => {
      if (window.scrollY - 1 < top && top < window.scrollY + 1) {
        clearInterval(checkIfScrollIsStatic)
        const autofocus = targetLi.querySelector('.autofocus')
        if (autofocus) { autofocus.focus() }
      }
    }, 50)
  }, 100)
}

const calculateScrollTop = (slug, targetLi) => {
  const li = document.getElementById(slug)
  if (!li) { return 0 }
  const ul = document.getElementById('question-list')

  const idealLiHeight = 500
  const ulExtraPaddingBottom = idealLiHeight - targetLi.offsetHeight
  const ulDefaultPaddingBottom = getDefaultContainerPaddingBottom(ul)
  ul.style.paddingBottom = `${ulDefaultPaddingBottom + ulExtraPaddingBottom}px`

  return li.getBoundingClientRect().bottom +
    document.documentElement.scrollTop -
    getVerticalOffset()
}

const getVerticalOffset = () => {
  const width = window.innerWidth

  if (width < parseInt(breakpoints.large)) {
    return parseInt(verticalOffsets.small)
  }

  return parseInt(verticalOffsets.large)
}

// We only want to the computed bottom padding on the first scroll.
let containerDefaultPaddingBottom = null
const getDefaultContainerPaddingBottom = (ul) => {
  if (!!containerDefaultPaddingBottom) {
    return containerDefaultPaddingBottom
  }

  containerDefaultPaddingBottom = parseInt(window.getComputedStyle(ul, null).getPropertyValue('padding-bottom'))
  return containerDefaultPaddingBottom
}