import styled from 'styled-components'

export const breakpoints = {
  small: '650px',
  large: '1100px'
}

export const verticalMargins = {
  large: '12vh',
  small: '5vh'
}

export const verticalOffsets = {
  large: '150px',
  small: '20px'
}

export const marginBetweenChoicesAndButtons = {
  large: '20px',
  small: '5px'
}

export const Container = styled.div`
  width: ${breakpoints.small};
  margin: 0 auto;

  @media (max-width: ${breakpoints.small}) {
    width: 85%;
  }
`

export const Flex = styled.div`
  display: flex;
`

export const Button = styled.input`
  appearance: none;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  letter-spacing: 2px;
  border: 1px solid #222;
  font-size: 14px;
  font-weight: 400;
  line-height: 37px;
  width: 180px;
  text-transform: uppercase;
  border-radius: 5px;
  background: #222;
  color: #F6F8F7;
  padding: 8px 20px;
  display: inline-block;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  :hover {
    background-color: #333;
    color: #F6F8F7;
  }
`

export const NextButton = styled(Button)`
  margin-top: ${marginBetweenChoicesAndButtons.large};
  font-size: 14px;
  padding: 2px 20px 2px 28px;
  float: right;
  width: auto;
  background: transparent;
  color: #222;
  border-radius: 10px;

  :hover, :active, :visited {
    background: #222;
  }
`

export const StyledSubmitButton = styled(Button)`
  float: right;
  padding: 16px 40px;
  text-transform: none;
  letter-spacing: 1px;
  width: auto;
  font-size: 14px;
  font-weight: bold;
  border-radius: 500px;

  :focus {
    outline: none;
  }

  @media (max-width: ${breakpoints.small}) {
    width: 100%;
    font-size: 20px;
  }
`

export const TextInput = styled.input`
  background: transparent;
  font-weight: bold;
  font-size: 20px;
  padding: 16px;
  border: 1px solid #222;
  margin-right: 10px;
  border-radius: 5px;
  outline: none;
  width: 100%;
  flex-grow: 1;

  ::placeholder {
    color: #aaa;
  }
`

export const Title = styled.h1`
  font-size: 55px;
  font-weight: 700;
  line-height: 60.5px;

  @media (max-width: ${breakpoints.small}) {
    font-size: 45px;
    line-height: 48px;
  }
`

export const Subtitle = styled.h2`
  font-size: 17px;
  font-weight: 500;
  line-height: 27px;

  a {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    display: block;
  }
`

export const SubtitleLink = styled.a`
  color: #4888e8;
  font-weight: 700;
  letter-spacing: normal;

  &:hover {
    color: #1960ca;
  }
`

export const BadgeList = styled.ul`
  margin: 8px 0 0;
  height: 36px;
  overflow: hidden;
`

export const BadgeListItem = styled.li`
  display: inline-block;
  background: #39DCBF;
  border-radius: 6px;
  padding: 2px 12px 0;
  display: inline-block;
  color: #fff;
  font-size: 14px;
  line-height: 30px;
  font-weight: 600;
  margin: 4px 4px 0 0;
`