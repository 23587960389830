import { RecoilRoot } from 'recoil'
import smoothscroll from 'smoothscroll-polyfill'
import PlausibleProvider from 'next-plausible'

import Layout from '../components/layout'

if (typeof window !== 'undefined') {
  smoothscroll.polyfill()
}

import '../styles/globals.css'
import '../styles/reset.css'

function MyApp({ Component, pageProps }) {
  const getLayout = Component.getLayout || ((page) => <Layout>{page}</Layout>)
  const component = getLayout(<Component {...pageProps} />)

  return (
    <RecoilRoot>
      <PlausibleProvider domain='polyfill.work'>
        {component}
      </PlausibleProvider>
    </RecoilRoot>
  )
}

export default MyApp
