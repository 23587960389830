import { forwardRef } from 'react'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'
import { navOpenState } from '../state/atoms'
import { Container, breakpoints } from '../styles/shared.css'

const HeaderContainer = styled.header`
  position: sticky;
  margin: 0;
  top: -1px;
  padding: 20px 0;
  z-index: 3;

  &[stuck], &.stuck {
    background: #fff;
  }

  .hidden-unless-stuck {
    display: none;
  }

  &[stuck] .hidden-unless-stuck, &.stuck .hidden-unless-stuck {
    display: block;
  }
`

const FlexContainer = styled(Container)`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.search-header {
    margin: 0 60px 0 auto;
    width: calc(100% - 100px);
  }

  &.search-checkout-header {
    max-width: 1340px;
    width: calc(100% - 100px);

    @media (max-width: ${breakpoints.small}) {
      width: calc(100% - 40px);
    }
  }
`

const Logo = styled.div`
  font-weight: 900;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 2.8px;
`

const Nav = styled.nav`

  a {
    color: #878B8B;
  }

  a:hover {
    color: #282828;
  }
`

const Hamburger = styled.button`
  appearance: none;
  border: none;
  background: none;
  font-size: 24px;
  cursor: pointer;
  display: block;
  z-index: 999;
  position: relative;
  color: #555;

  &:hover {
    color: #000;
  }
`

const Header = (props, ref) => {
  const [navOpen, setNavOpen] = useRecoilState(navOpenState)

  return <HeaderContainer ref={ref} className={props.stuck && 'stuck'}>
    <FlexContainer className={props.className && props.className}>
      <Logo>
        <a href='/'>Polyfill</a>
      </Logo>
      <Nav>
        <Hamburger onClick={() => setNavOpen(!navOpen)}>☰</Hamburger>
      </Nav>
    </FlexContainer>
  </HeaderContainer>
}

export default forwardRef(Header)