import { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'

import { Container, breakpoints } from '../styles/shared.css'
import { flashState, flashLocalStorageKey } from '../state/atoms'

const FlashMessage = styled.div`
  background: #F8EFBA;
  padding: 8px;
  color: black;
  background: #39DCBF;
  color: #fff;

  @media (max-width: ${breakpoints.small}) {
    margin-bottom: 10px;
  }

  @media (max-width: ${breakpoints.small}) and (prefers-color-scheme: dark) {
    background: #625509;
  }
`

const FlashMessageContent = styled(Container)`
  font-size: 14px;
  line-height: 20px;
  text-align: left;

  em {
    font-weight: 700;
  }
`

export default function Flash() {
  const [flash] = useRecoilState(flashState)

  useEffect(() => {
    window.localStorage.removeItem(flashLocalStorageKey)
  }, [])

  if (!flash) { return null }

  return <FlashMessage>
    <FlashMessageContent dangerouslySetInnerHTML={{__html: flash}} />
  </FlashMessage>
}
