import Meta from './meta'
import Header from './header'
import Flash from './flash'
import MovedByNav from './movedByNav'
import Nav from './nav'
import { Container } from '../styles/shared.css'

export default function Layout({ meta, children, container, headerClass, headerRef }) {
  const ContainerEl = container || Container

  // some pages overwrite this with a `getHeader` method so if you
  // update this layout make sure it doesnt break any of the pages
  // that overwrite the header using this method.

  return <>
    <Meta meta={meta} />
    <MovedByNav>
      <Header stuck={!headerRef} className={headerClass} ref={headerRef} />
      <Flash />
      <ContainerEl>
        {children}
      </ContainerEl>
    </MovedByNav>
    <Nav />
  </>
}